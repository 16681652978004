import { Controller } from "@hotwired/stimulus"
import * as Morph from "web-ui/turbo/stream-actions/morph"

// Connects to data-controller="field-preview"
export default class extends Controller {
  static values = {
    debug: Boolean,
    sourceId: String
  }

  static targets = [ "destination" ]

  connect() {
    this.debug("Connect")

    const sourceTarget = this.sourceTarget()
    sourceTarget.addEventListener("input", this.onInput)
    sourceTarget.addEventListener("focus", this.onFocus)
    sourceTarget.addEventListener("blur", this.onBlur)
  }

  disconnect() {
    const sourceTarget = this.sourceTarget()
    if (sourceTarget !== null) {
      sourceTarget.removeEventListener("input", this.onInput)
      sourceTarget.removeEventListener("focus", this.onFocus)
      sourceTarget.removeEventListener("blur", this.onBlur)
    }
  }

  onInput = (event) => {
    const value = event.target.value.trim()

    if (value === null || value === "") {
      this.hidePreview()
      this.updatePreview(value)
    } else {
      this.updatePreview(value)
      this.showPreview()
    }
  }

  onFocus = () => {
    Morph.ignore(this.element, true)
  }

  onBlur = () => {
    Morph.ignore(this.element, false)
  }

  showPreview() {
    this.element.firstChild.hidden = false
  }

  hidePreview() {
    this.element.firstChild.hidden = true
  }

  updatePreview(text) {
    // We are setting the element's content using `textContent`
    // to ensure the application is not vulnerable to HTML injection
    // - Nick, Scott A, Thu Feb 15 2024
    this.destinationTarget.textContent = text
  }

  sourceTarget() {
    return document.getElementById(this.sourceIdValue)
  }

  debug(message, value=null) {
    if (this.debugValue) {
      if (value !== null) {
        console.log("Field preview - " + message, value)
      } else {
        console.log("Field preview - " + message)
      }
    }
  }
}
