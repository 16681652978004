import morphdom from "morphdom"
import { morphdomPreserveAttributes } from "web-ui/morphdom-preserve-attributes"
import { morphdomShouldUpdate } from "web-ui/morphdom-should-update"

export function ignore(element, ignore) {
  if (ignore) {
    element.dataset.turboMorphIgnore = ''
  } else {
    delete element.dataset.turboMorphIgnore
  }
}

function morph() {
  const options = {
    childrenOnly: this.hasAttribute("children-only"),
    onBeforeElUpdated: (fromElement, toElement) => {
      morphdomPreserveAttributes(fromElement, toElement)
      return morphdomShouldUpdate(fromElement, toElement)
    },

    onBeforeNodeDiscarded: (node) => {
      if (!(node instanceof HTMLElement)) {
        return true
      }

      // Do not remove elements that indicate they should be ignored
      if ("turboMorphIgnore" in node.dataset) {
        return false
      }

      return true
    }
  }

  this.targetElements.forEach(element => {
    let newElement

    if (options.childrenOnly) {
      newElement = this.templateContent
    } else {
      newElement = this.templateElement.innerHTML
    }

    morphdom(element, newElement, options)
  })
}

export function activate(Turbo) {
  Turbo.StreamActions.morph = morph
}
