import { Controller } from "@hotwired/stimulus"
import * as Morph from "web-ui/turbo/stream-actions/morph"

// Connects to data-controller="textarea"
export default class extends Controller {
  static values = {
    debug: Boolean
  }

  connect() {
    this.debug("Connect")

    this.element.addEventListener("focus", this.onFocus)
    this.element.addEventListener("blur", this.onBlur)
    this.element.addEventListener("input", this.resize)
  }

  disconnect() {
    this.debug("Disconnect")

    this.element.removeEventListener("focus", this.onFocus)
    this.element.removeEventListener("blur", this.onBlur)
    this.element.removeEventListener("input", this.resize)
  }

  resize = (e) => {
    const textareaElement = e.target

    this.debug(`Resizing textarea (ID: ${textareaElement.id})`)

    this.element.dataset.text = textareaElement.value
  }

  onFocus = () => {
    Morph.ignore(this.element, true)
  }

  onBlur = () => {
    Morph.ignore(this.element, false)
  }

  debug(message) {
    if (this.debugValue) {
      console.log("Textarea - " + message)
    }
  }
}
