export function morphdomPreserveAttributes(fromElement, toElement) {
  const dataset = fromElement.dataset

  const preserveAttributesAttributeName = "turboMorphPreserveAttributes"

  if (!dataset.hasOwnProperty(preserveAttributesAttributeName)) {
    return
  }

  const delimitedAttributes = dataset[preserveAttributesAttributeName]
  const attributes = delimitedAttributes.split(" ")

  for (let attribute of attributes) {
    const hasAttribute = fromElement.hasAttribute(attribute)
    if (hasAttribute) {
      const value = fromElement.getAttribute(attribute)
      toElement.setAttribute(attribute, value)
    } else {
      toElement.removeAttribute(attribute)
    }
  }
}

