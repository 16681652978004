import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal-dialog"
export default class extends Controller {
  connect() {
    this.element.showModal()
    this.element.addEventListener("close", this.remove)
  }

  disconnect() {
    this.element.removeEventListener("close", this.remove)
  }

  close() {
    this.element.close()
  }

  remove = () => {
    this.element.remove()
  }
}
