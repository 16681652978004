import { Controller } from "@hotwired/stimulus"
import { computePosition, flip, shift, autoUpdate } from "@floating-ui/dom"
import * as Morph from "web-ui/turbo/stream-actions/morph"

// Connects to data-controller="popover"
export default class extends Controller {
  static targets = ["container", "button", "popover"]

  isVisible() {
    return this.containerTarget.classList.contains("visible")
  }

  closePopover() {
    Morph.ignore(this.element, false)
    this.containerTarget.classList.remove("visible", "top", "bottom")
    this.buttonTarget.removeAttribute("aria-expanded")

    if (this.disableAutoUpdate) {
      this.disableAutoUpdate()
    }
  }

  open() {
    Morph.ignore(this.element, true)
    this.containerTarget.classList.add("visible")
    this.buttonTarget.setAttribute("aria-expanded", "true")
    this.popoverTarget.scrollTop = 0

    this.disableAutoUpdate = autoUpdate(
      this.buttonTarget,
      this.popoverTarget,
      this.updatePosition
    );
  }

  updatePosition = () => {
    computePosition(this.buttonTarget, this.popoverTarget, {
      placement: "top",
      middleware: [shift(), flip({ padding: 16 })],
    }).then(({ placement, middlewareData: { shift } }) => {
      if (placement === "top") {
        this.containerTarget.classList.add("top")
        this.containerTarget.classList.remove("bottom")
      } else {
        this.containerTarget.classList.add("bottom")
        this.containerTarget.classList.remove("top")
      }

      if (shift.x > 0) {
        this.popoverTarget.style.left = `${shift.x}px`
      }
    })
  }

  close(event) {
    if (!this.isVisible()) {
      return
    }

    if (event) {
      if (event.key === "Escape") {
        this.closePopover()
      }

      if (this.element.contains(event.target)) {
        return
      }
    }

    this.closePopover()
  }

  toggle() {
    this.isVisible() ? this.close() : this.open()
  }
}
