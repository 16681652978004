import morphdom from "morphdom"
import { morphdomPreserveAttributes } from "web-ui/morphdom-preserve-attributes"
import { morphdomShouldUpdate } from "web-ui/morphdom-should-update"

function morphdomRender({ detail }) {
  detail.render = (fromElement, toElement) => {
    morphdom(fromElement, toElement, {
      childrenOnly: true,
      onBeforeElUpdated: (fromElement, toElement) => {
        morphdomPreserveAttributes(fromElement, toElement)
        return morphdomShouldUpdate(fromElement, toElement)
      }
    })
  }
}

export function activate() {
  addEventListener("turbo:before-frame-render", morphdomRender)
}
