import { Controller } from "@hotwired/stimulus"

const defaultIntervalSeconds = 10

// Connects to data-controller="refresh"
export default class extends Controller {
  static values = {
    intervalSeconds: Number,
    url: String
  }

  connect() {
    this.submissionCount = 0
    this.lastRefreshTime = Date.now()

    this.startInterval()

    document.addEventListener("turbo:submit-start", this.recordSubmitStart)
    document.addEventListener("turbo:submit-end", this.recordSubmitEnd)
    this.element.addEventListener("turbo:before-frame-render", this.beforeFrameRender)
  }

  disconnect() {
    clearInterval(this.interval)
    this.interval = null
    document.removeEventListener("turbo:submit-start", this.recordSubmitStart)
    document.removeEventListener("turbo:submit-end", this.recordSubmitEnd)
    this.element.removeEventListener("turbo:before-frame-render", this.beforeFrameRender)
  }

  startInterval() {
    clearInterval(this.interval)

    const intervalTimerMilliseconds = this.intervalMilliseconds / 10

    this.interval = setInterval(this.poll, intervalTimerMilliseconds)
  }

  get intervalMilliseconds() {
    const intervalSeconds = this.intervalSecondsValue || defaultIntervalSeconds
    return intervalSeconds * 1000
  }

  intervalSecondsValueChanged() {
    if (!this.interval) {
      // Ignore the change if the interval is not in progress. This happens when
      // the controller connects initially. - Aaron, Thu May 9 2024
      return
    }

    this.startInterval()
  }

  beforeFrameRender = (e) => {
    if (this.shouldRefresh()) {
      const newFrame = e.detail.newFrame

      for (const key in newFrame.dataset) {
        this.element.dataset[key] = newFrame.dataset[key]
      }
    } else {
      // Addresses a race condition where a pending refresh would render while a refresh
      // should not occur (e.g., a form is submitted while a refresh fetch is outstanding)
      // - Kelly, Aaron, Tue Feb 07 2023
      e.detail.render = () => {}
      e.stopPropagation()
    }
  }

  shouldRefresh() {
    if (document.hidden) return false
    if (this.element.getAttribute('aria-busy')) return false
    if (this.submissionCount > 0) return false

    return true
  }

  recordSubmitStart = () => {
    this.submissionCount += 1
  }

  recordSubmitEnd = () => {
    if (this.submissionCount > 0) {
      this.submissionCount -= 1
    }
  }

  poll = () => {
    const now = Date.now()
    const millisecondsSinceLastRefresh = now - this.lastRefreshTime

    if (millisecondsSinceLastRefresh < this.intervalMilliseconds) {
      return
    }

    if (this.shouldRefresh()) {
      this.refresh()
    }
  }

  refresh() {
    this.lastRefreshTime = Date.now()
    this.element.src = null
    this.element.src = this.urlValue
  }
}
