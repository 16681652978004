import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switch"
export default class extends Controller {
  static values = {
    debug: Boolean
  }

  connect() {
    this.debug("Connect")

    this.element.addEventListener("change", this.submitForm)
  }

  disconnect() {
    this.debug("Disconnect")

    this.element.removeEventListener("change", this.submitForm)
  }

  submitForm = (e) => {
    const option = e.target
    const form = option.form

    this.debug(`Submitting form (ID: ${form.id}, option ID: ${option.id})`)

    form.requestSubmit()
  }

  debug(message) {
    if (this.debugValue) {
      console.log("Switch - " + message)
    }
  }
}
