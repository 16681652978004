import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="view-form-error"
export default class extends Controller {

  viewError() {
    const invalidField = document.querySelector("form .field.invalid, form .message.error")

    if (invalidField) {
      invalidField.scrollIntoView({ behavior: "smooth", block: "center" })

      const input = invalidField.querySelector("input, textarea, select")

      if (input) {
        input.focus({ preventScroll: true })
      }
    }
  }
}
