import { morphdomMorphTemplate } from "web-ui/morphdom-morph-template"

function isFocused(element) {
  const isInput = ["INPUT", "SELECT", "TEXTAREA"].includes(element.tagName)
  if (!isInput) {
    return false
  }

  return document.hasFocus() && element === document.activeElement
}

function isTemplate(element) {
  return element.tagName === "TEMPLATE"
}

export function morphdomShouldUpdate(fromElement, toElement) {
  if (isTemplate(fromElement)) {
    morphdomMorphTemplate(fromElement, toElement)
    return false
  }

  // Do not update anything that has focus, unless the toElement is disabled
  // - Aaron, Wed Aug 09 2023
  if (isFocused(fromElement) && !toElement.disabled) {
    return false
  }

  // Do not update elements that indicate they should be ignored
  if ("turboMorphIgnore" in fromElement.dataset) {
    return false
  }

  return true
}
