import { Controller } from "@hotwired/stimulus"
import * as Morph from "web-ui/turbo/stream-actions/morph"
import { setInputValueAttributes } from "web-ui/form/set-input-value-attributes"

// Set dynamic_form_debug_value to true to observe and diagnose

// Connects to data-controller="dynamic-form"
export default class extends Controller {
  static values = {
    debug: Boolean
  }

  connect() {
    this.debug("Connect")

    this.changedElementNames = new Set()
    this.updatingElementNames = new Set()
    this.actions = {}
    this.updating = false
    this.updateDelay = 0

    this.element.addEventListener("change", this.change)
    this.element.addEventListener("submit", this.submit)
  }

  disconnect() {
    this.debug("Disconnect")

    this.element.removeEventListener("change", this.change)
    this.element.removeEventListener("submit", this.submit)
  }

  submit = (event) => {
    const submitter = event.submitter

    if (submitter == null) {
      return
    }

    const dataset = submitter.dataset
    const formUpdatesJSON = dataset.formUpdates
    if (formUpdatesJSON == null) {
      return
    }

    event.preventDefault()

    const formUpdates = JSON.parse(formUpdatesJSON)
    for (const [name, value] of Object.entries(formUpdates)) {
      this.actions[name] = value
    }

    const form = submitter.form
    this.requestUpdate(form)
  }

  change = (event) => {
    const input = event.target

    // Avoid updating disabled inputs, which can happen if a modification is
    // made to an input, and while it is still focused, an update disables that
    // input before it is blurred - Aaron, Wed Aug 09 2023
    if (input.disabled) {
      return
    }

    setInputValueAttributes(input)

    const form = input.form

    this.initiateChange(input.name, form)
  }

  //## This should be removed after migrating to the new method of submitting
  //## actions - Nick, Scott A, Aaron, Thu Apr 18 2024
  // Add data-action="click->dynamic-form#submitAction" to a button of
  // type "button" in the form to have it submit its name and value along with
  // the next update batch. This is used to add and remove collection items, but
  // it could be used for anything else that requires one-off modifications to
  // the form to be submitted.
  // - Aaron, Thu Dec 08 2022
  //
  // Note that the button type must be "button", rather than "submit" in order
  // to work around a bug in Safari 16.x where the submitter is "remembered"
  // across submits, causing problems with turbo's ability to handle subsequent
  // change events.
  // - Aaron, Tue Aug 15 2023
  submitAction(event) {
    event.preventDefault()

    const submitter = event.target
    const form = submitter.form

    const name = submitter.name
    const value = submitter.value
    this.actions[name] = value

    this.requestUpdate(form)
  }

  initiateChange(name, form) {
    this.morphIgnoreInputs(name, true, form)
    this.changedElementNames.add(name)
    this.debug(`Recorded change newCount=${this.changedElementNames.size} name=${name}`)

    this.requestUpdate(form)
  }

  requestUpdate(form) {
    if (this.isUpdating()) {
      return
    }

    if (this.updateDelay > 0) {
      this.debug(`Delaying update ${this.updateDelay}ms`)
    }

    this.updating = true

    // Ensure update happens on the next tick, rather than the current, so that
    // an event handler may make additional changes to the form before the
    // update is submitted.
    // - Aaron, Shaun, Fri Jan 5 2023
    setTimeout(() => {
      this.update(form)
    }, this.updateDelay)
  }

  morphIgnoreInputs(name, ignore, form) {
    const formElements = form.elements

    for (const elementName in formElements) {
      if (elementName === name) {
        const element = formElements[elementName]

        if (element.forEach) {
          element.forEach(element => {
            Morph.ignore(element, ignore)
          })
        } else {
          Morph.ignore(element, ignore)
        }
      }
    }
  }

  update(form) {
    this.updatingElementNames = this.changedElementNames
    this.changedElementNames = new Set()

    const actions = this.actions
    this.actions = {}

    const updateRequest = (event) => {
      for (const [name, value] of Object.entries(actions)) {
        event.detail.formSubmission.fetchRequest.body.append(name, value)
      }

      event.detail.formSubmission.fetchRequest.body.append("_method", "patch")
    }

    const completeUpdate = () => {
      // This function is called on turbo:submit-end, but the turbo stream morph
      // that the server responds with will not be applied until later. The only
      // known way to ensure that code can be run immediately before a morph and
      // immediately after is to add an event listener for
      // turbo:before-stream-render and override the event.detail.render method.
      //
      // In the overridden render method:
      //
      // 1. All fields that have been updated have their morph ignore removed
      //
      // 2. The original render is invoked, which applies the morph
      //
      // 3. The update is marked as complete
      //
      // 4. If there is a pending update, requestUpdate is invoked
      //
      // This code was originally added to prevent a race condition wherein a
      // field that was filled in could be subsequently cleared by a previous
      // update's morph being applied.
      // - Aaron, Thu Mar 21 2024
      const beforeStreamRender = (event) => {
        const stream = event.detail.newStream
        const action = stream.getAttribute("action")

        if (action !== "morph") {
          return
        }

        const target = stream.getAttribute("target")

        if (target !== this.element.id) {
          return
        }

        document.removeEventListener("turbo:before-stream-render", beforeStreamRender)

        const render = event.detail.render

        event.detail.render = (self) => {
          // This must be done here to ensure that there is still a pending update
          // when the update (morphdom) happens for this update. If we did it in
          // updateRequestBody, a twice updated field may flash its old value
          // - Aaron, Wed Jul 20 2022
          this.updatingElementNames.forEach(name => {
            // Do not unignore if there is another change enqueued
            if (!this.changedElementNames.has(name)) {
              this.morphIgnoreInputs(name, false, form)
            }
          })
          this.updatingElementNames.clear()

          render(self)

          this.updating = false

          this.debug(`Completed update newCount=${this.changedElementNames.size}`)

          if (this.hasPendingUpdates()) {
            this.requestUpdate(form)
          }
        }
      }

      document.addEventListener("turbo:before-stream-render", beforeStreamRender)
    }

    const addListener = () => {
      form.addEventListener("turbo:submit-start", updateRequest, { once: true })
    }

    form.addEventListener("turbo:before-fetch-request", addListener, { once: true })

    const ignoreServerErrors = (event) => {
      // The default behavior of turbo is to render the server error to the
      // user. Since we do not always control this error (our application
      // gateway can serve error pages that it controls), we do not render the
      // error page. - Aaron, Thu Apr 27 2023
      const response = event.detail.fetchResponse

      if (response.serverError) {
        this.debug(`Update failed due to server error`)
        event.preventDefault()


        Sentry.withScope(scope => {
          scope.setTag("statusCode", response.statusCode)
          scope.setExtra("headers", response.response.headers)

          Sentry.captureMessage("Dynamic form change failed", "error")
        })

        this.updatingElementNames.forEach(name => {
          this.changedElementNames.add(name)
        })

        this.updatingElementNames.clear()

        this.updating = false

        form.removeEventListener("turbo:submit-end", completeUpdate)

        this.updateDelay += 250
        this.updateDelay = Math.min(this.updateDelay, 5000)

        this.requestUpdate(form)
      } else {
        this.updateDelay = 0
      }
    }
    form.addEventListener("turbo:before-fetch-response", ignoreServerErrors, { once: true })

    form.addEventListener("turbo:submit-end", completeUpdate, { once: true })

    form.requestSubmit()

    form.removeEventListener("turbo:before-fetch-request", addListener)
  }

  hasPendingActions() {
    return Object.keys(this.actions).length > 0
  }

  hasPendingUpdates() {
    return this.changedElementNames.size > 0 || this.hasPendingActions()
  }

  isUpdating() {
    return this.updating
  }

  hasUnsubmittedChanges() {
    return this.hasChanged() && !this.isSubmitting()
  }

  hasChanged() {
    return this.changed
  }

  isSubmitting() {
    return this.submitting
  }

  isSubmitted() {
    return this.submitted
  }

  isCanceling() {
    return this.canceling
  }

  debug(message) {
    if (this.debugValue) {
      console.log("Dynamic form - " + message)
    }
  }
}
