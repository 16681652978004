import { Turbo } from "@hotwired/turbo-rails"
import * as ReplaceStylesheets from "web-ui/turbo/replace-stylesheets"
import * as MorphdomRender from "web-ui/turbo/morphdom-render"

import * as MorphStreamAction from "web-ui/turbo/stream-actions/morph"
import * as RedirectStreamAction from "web-ui/turbo/stream-actions/redirect"
import "web-ui/turbo/disable-submit-button"

ReplaceStylesheets.activate(Turbo)
MorphdomRender.activate(Turbo)

MorphStreamAction.activate(Turbo)
RedirectStreamAction.activate(Turbo)

Turbo.session.drive = false

