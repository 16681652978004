function showConfirmationDialog(confirmationTemplateID) {
  const dialogTemplate = document.querySelector(`#${confirmationTemplateID}`)

  if (!dialogTemplate) {
    return
  }

  const dialogFragment = dialogTemplate.content

  const dialog = dialogFragment.firstElementChild.cloneNode(true)
  document.body.appendChild(dialog)
  dialog.showModal()

  return new Promise((resolve, _reject) => {
    dialog.addEventListener("close", () => {
      resolve(dialog.returnValue == "confirm")
    }, { once: true })
  }).finally(() => {
    dialog.remove()
  })
}

const confirmations = new WeakSet()

async function handleClick(event) {
  const target = event.target

  const dataset = target.dataset
  const confirmationTemplateID = dataset.confirmationTemplateId

  if (!confirmationTemplateID) {
    return
  }

  if (confirmations.has(target)) {
    confirmations.delete(target)
    return
  }

  event.preventDefault()
  event.stopPropagation()

  const confirmed = await showConfirmationDialog(confirmationTemplateID)

  if (confirmed) {
    confirmations.add(target)
    target.click()
  }
}

export function activate() {
  document.addEventListener("click", handleClick, {
    capture: true
  })
}
