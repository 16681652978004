// Morphdom uses the value of the `value` attribute for inputs to determine
// whether or not to update the element. For selects, it uses the `selected`
// attribute of `option` elements. It is possible for the value attribute to not
// have the same value as the actual input's value when the value changes. This
// function can be invoked to keep them in sync.
//
// Without this, for example, when a number field with the value of "4" is changed
// to "4a" in the browser: the server will respond with an element that has a
// value of "4", which will be the same as the browser version. This ensures that
// that value attribute in the browser will change to "4a" before attempting to
// morph, so a change will be detected, causing the input to change back to "4"
// in the browser.
// - Aaron, Mon Mar 4 2024
export function setInputValueAttributes(input) {
  const value = input.value

  if (input instanceof HTMLInputElement) {
    input.setAttribute("value", value)
  } else if (input instanceof HTMLSelectElement) {
    const options = input.querySelectorAll("option")

    options.forEach(option => {
      if (option.value == value) {
        option.setAttribute("selected", "")
      } else {
        option.removeAttribute("selected")
      }
    })
  } else if (input instanceof HTMLTextAreaElement) {
    input.textContent = value
  }
}
