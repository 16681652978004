import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="confirm-form"
export default class extends Controller {
  static targets = ["button"];

  validate(e) {
    const text = e.target.value;

    if (text.toLowerCase() === "confirm") {
      this.buttonTarget.removeAttribute("disabled");
    } else {
      this.buttonTarget.setAttribute("disabled", "disabled");
    }
  }
}
