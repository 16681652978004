const debug = (window.location.search || "").includes("debug")

const log = (...args) => {
  if (debug) {
    console.log(...args)
  }
}

function removeFingerprint(url) {
  if (!url) { return url }
  return url.replace(/-[0-9a-f]{7,128}(\.digested)?\.css$/, ".css")
}

function stylesheetElementIsReplaceable(element) {
  return element.getAttribute("data-turbo-track") == "replace" &&
    element.getAttribute("href") != null
}

// Copied from turbo
function waitForLoad(element, timeoutInMilliseconds = 2000) {
  return new Promise((resolve) => {
    const onComplete = () => {
      element.removeEventListener("error", onComplete)
      element.removeEventListener("load", onComplete)
      resolve()
    }

    element.addEventListener("load", onComplete, { once: true })
    element.addEventListener("error", onComplete, { once: true })
    setTimeout(resolve, timeoutInMilliseconds)
  })
}

// Original:
// async copyNewHeadStylesheetElements() {
//   const loadingElements = []
//
//   for (const element of this.newHeadStylesheetElements) {
//     loadingElements.push(waitForLoad(element as HTMLLinkElement))
//
//     document.head.appendChild(element)
//   }
//
//   await Promise.all(loadingElements)
// }

async function copyNewHeadStylesheetElements() {
  log("copyNewHeadStylesheetElements")

  const loadingElements = []
  const preloadElements = []

  const newStylesheetElements = this.newHeadStylesheetElements.filter(stylesheetElementIsReplaceable)
  const oldStylesheetElements = this.currentHeadSnapshot.getStylesheetElementsNotInSnapshot(this.newHeadSnapshot).filter(stylesheetElementIsReplaceable)

  const oldElementIndex = Object.fromEntries(oldStylesheetElements.map(element => [removeFingerprint(element.getAttribute("href")), element]))

  let lastStylesheetElement = null

  for (const newElement of newStylesheetElements) {
    const newHref = newElement.getAttribute("href")
    const hrefWithoutFingerprint = removeFingerprint(newHref)
    const oldElement = oldElementIndex[hrefWithoutFingerprint]
    const oldHref = oldElement && oldElement.getAttribute("href")

    if (oldHref == newHref) {
      log(`Skipping ${newHref}`)
      delete oldElementIndex[hrefWithoutFingerprint]
      continue
    }

    // Convert the link tag to a preload so that it can be enabled at the same
    // time as others (after everything has loaded) and any old stylesheets can
    // be removed at the same time as well.
    newElement.setAttribute("as", "style")
    newElement.setAttribute("rel", "preload")
    preloadElements.push(newElement)

    loadingElements.push(waitForLoad(newElement))

    if (oldElement) {
      log(`Updating ${oldElement.getAttribute("href")} to ${newHref}`)

      oldElement.after(newElement)
    } else {
      log(`Appending ${newHref} after`, lastStylesheetElement)

      // Ensure that the new elements maintain their order as much as possible
      if (lastStylesheetElement) {
        lastStylesheetElement.after(newElement)
      } else {
        document.head.appendChild(newElement)
      }
    }

    lastStylesheetElement = newElement
  }

  await Promise.all(loadingElements)

  for (const element of Object.values(oldElementIndex)) {
    log(`Removing ${element.getAttribute("href")}`)

    element.remove()
  }

  for (const element of preloadElements) {
    log(`Converting preload ${element.getAttribute("href")}`)

    element.setAttribute("rel", "stylesheet")
    element.removeAttribute("as")
  }
}

export function activate(Turbo) {
  Turbo.PageRenderer.prototype.copyNewHeadStylesheetElements = copyNewHeadStylesheetElements
}
