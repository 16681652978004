// Form submission buttons are disabled if form submissions are a redirect
// https://github.com/hotwired/turbo/issues/766
// - Shaun, Aaron, Mon Mar 11 2024

const buttonsInsideForm = (form) => {
  const buttons = Array.from(form.querySelectorAll("[type=submit]"))

  return buttons.filter(b => !b.disabled)
}

const buttonsOutsideForm = (formID) => {
  const buttons = Array.from(document.querySelectorAll(`[type=submit][form=${formID}]`))

  return buttons.filter(b => !b.disabled)
}

const enabledSubmitButtons = (form) => {
  const formID = form.id

  if (formID) {
    return [
      ...buttonsInsideForm(form),
      ...buttonsOutsideForm(formID)
    ]
  }

  return buttonsInsideForm(form)
}

const disableButtonsOnRedirect = (event) =>  {
  const fetchResponse = event.detail.fetchResponse

  // Fetch response may not be present if the request was canceled
  // - Shaun, Antoine, Stephen, Tue Mar 12 2024
  if (fetchResponse && fetchResponse.redirected) {
    const form = event.target

    const enabledButtons = enabledSubmitButtons(form)

    for (const button of enabledButtons) {
      button.disabled = true
    }

    const turboStreamRedirect = fetchResponse.contentType.includes("text/vnd.turbo-stream.html")
    if (turboStreamRedirect) {
      const restoreButtons = () => {
        for (const button of enabledButtons) {
          button.disabled = false
        }
      }

      document.addEventListener("turbo:before-stream-render", restoreButtons, { once: true })
    }
  }
}

document.addEventListener("turbo:submit-end", disableButtonsOnRedirect)





// document.addEventListener("turbo:submit-end", (event) => {
//   const fetchResponse = event.detail.fetchResponse

//   // Fetch response may not be present if the request was canceled
//   // - Shaun, Antoine, Stephen, Tue Mar 12 2024
//   if (!fetchResponse) {
//     return
//   }

//   if (!fetchResponse.redirected) {
//     return
//   }

//   const form = event.target
//   const enabledButtons = []

//   const disableButton = (button) => {
//     if (!button.disabled) {
//       enabledButtons.push(button)
//     }

//     button.disabled = true
//   }

//   form.querySelectorAll("[type=submit]").forEach(disableButton)

//   const formID = form.id
//   if (formID) {
//     document.querySelectorAll(`[type=submit][form=${formID}]`).forEach(disableButton)
//   }

//   const restoreButtons = () => {
//     for (const button of enabledButtons) {
//       button.disabled = false
//     }
//   }

//   const turboStreamRedirect = fetchResponse.contentType.includes("text/vnd.turbo-stream.html")
//   if (turboStreamRedirect) {
//     document.addEventListener("turbo:before-stream-render", restoreButtons, { once: true })
//   }
// });
