function redirect() {
  const path = this.getAttribute("path")

  if (!path) {
    throw new Error("Path is not specified")
  }

  window.location = path
}

export function activate(Turbo) {
  Turbo.StreamActions.redirect = redirect
}
