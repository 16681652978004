import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="option-group"
export default class extends Controller {
  static values = {
    debug: Boolean
  }

  static targets = [
    "option"
  ]

  connect() {
    this.debug("Connect")

    this.element.addEventListener("change", this.onChange)
  }

  disconnect() {
    this.debug("Disconnect")

    this.element.removeEventListener("change", this.onChange)
  }

  optionTargetConnected(element) {
    this.debug(`Option connected ${element.name}`)
  }

  optionTargetDisconnected(element) {
    this.debug(`Option disconnected ${element.name}`)
  }

  onChange = (e) => {
    const option = e.target
    const checked = option.checked

    if (checked) {
      this.reconcile(option)
    }
  }

  reconcile(selectedOption) {
    let options = this.optionTargets.filter(option => option != selectedOption)

    options = options.filter(option => option.checked)

    const exclusiveSelectedOption = "exclusive" in selectedOption.dataset
    if (!exclusiveSelectedOption) {
      options = options.filter(option => "exclusive" in option.dataset)
    }

    options.forEach(option => {
      this.debug(`Unchecking option name=${option.name}`)

      option.checked = false

      const event = new Event("change", { bubbles: true })
      option.dispatchEvent(event)
    })
  }

  debug(message) {
    if (this.debugValue) {
      console.log("Option group - " + message)
    }
  }
}
