import { application } from "web-ui/controllers/application"
import DynamicForm from "web-ui/controllers/dynamic-form"
import DynamicFormDiscardChangesConfirmation from "web-ui/controllers/dynamic-form/discard-changes-confirmation"
import CollaborativeForm from "web-ui/controllers/collaborative-form"
import Textarea from "web-ui/controllers/textarea"
import DropdownMenu from "web-ui/controllers/dropdown-menu"
import Refresh from "web-ui/controllers/refresh"
import Popover from "web-ui/controllers/popover"
import SynchronizedForm from "web-ui/controllers/synchronized-form"
import UserTools from "web-ui/controllers/user-tools"
import ViewFormError from "web-ui/controllers/view-form-error"
import ModalDialog from "web-ui/controllers/modal-dialog"
import AsyncSelect from "web-ui/controllers/async-select"
import ConfirmForm from "web-ui/controllers/confirm-form"
import Switch from "web-ui/controllers/switch"
import OptionGroup from "web-ui/controllers/option-group"
import FieldPreview from "web-ui/controllers/field-preview"

application.register("dynamic-form", DynamicForm)
application.register("dynamic-form-discard-changes-confirmation", DynamicFormDiscardChangesConfirmation)
application.register("collaborative-form", CollaborativeForm)
application.register("textarea", Textarea)
application.register("dropdown-menu", DropdownMenu)
application.register("refresh", Refresh)
application.register("popover", Popover)
application.register("synchronized-form", SynchronizedForm)
application.register("user-tools", UserTools)
application.register("view-form-error", ViewFormError)
application.register("modal-dialog", ModalDialog)
application.register("async-select", AsyncSelect)
application.register("confirm-form", ConfirmForm)
application.register("switch", Switch)
application.register("option-group", OptionGroup)
application.register("field-preview", FieldPreview)

export { application as stimulusApplication }
