import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dropdown-menu"
export default class extends Controller {
  static targets = ["button"];

  isOpen() {
    return this.element.classList.contains("open");
  }

  closeMenu(event) {
    if (event && this.element.contains(event.target)) {
      return;
    }

    this.element.classList.remove("open");
    this.buttonTarget.removeAttribute("aria-expanded");
  }

  openMenu() {
    this.element.classList.add("open");
    this.buttonTarget.setAttribute("aria-expanded", "true");
  }

  toggle() {
    if (this.isOpen()) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }
}
