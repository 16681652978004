import { Controller } from "@hotwired/stimulus"
import * as Morph from "web-ui/turbo/stream-actions/morph"
import { setInputValueAttributes } from "web-ui/form/set-input-value-attributes"

const refreshIntervalMilliseconds = 10 * 1000

// Set collaborative_form_debug_value to true to observe and diagnose

// Connects to data-controller="collaborative-form"
export default class extends Controller {
  static values = {
    refreshPath: String,
    debug: Boolean
  }

  connect() {
    this.debug("Connect")
    this.refreshing = false
    this.lastRefreshTime = Date.now()
    this.changedElementNames = new Set()
    this.updatingElementNames = new Set()
    this.actions = {}
    this.updating = false
    this.updateDelay = 0

    this.startRefreshInterval()

    document.addEventListener("visibilitychange", this.handleVisibilityChange)
    this.element.addEventListener("submit", this.submit)
  }

  disconnect() {
    this.debug("Disconnect")

    document.removeEventListener("visibilitychange", this.handleVisibilityChange)
    this.element.removeEventListener("submit", this.submit)

    this.stopRefreshInterval()
  }

  startRefreshInterval() {
    this.stopRefreshInterval()

    this.refreshInterval = setInterval(() => {
      if (this.shouldRefresh()) {
        this.refresh()
      }
    }, refreshIntervalMilliseconds / 10)
  }

  stopRefreshInterval() {
    clearInterval(this.refreshInterval)
  }

  handleVisibilityChange = () => {
    if (this.shouldRefresh()) {
      this.refresh()
    }
  }

  submit = (event) => {
    const submitter = event.submitter

    if (submitter == null) {
      return
    }

    const dataset = submitter.dataset
    const formUpdatesJSON = dataset.formUpdates
    if (formUpdatesJSON == null) {
      return
    }

    event.preventDefault()

    const formUpdates = JSON.parse(formUpdatesJSON)
    for (const [name, value] of Object.entries(formUpdates)) {
      this.actions[name] = value
    }

    const form = submitter.form
    this.requestUpdate(form)
  }

  shouldRefresh() {
    if (this.element.getAttribute('busy') != null) {
      return false
    }

    if (document.hidden) {
      return false
    }

    if (this.hasPendingUpdates() || this.isUpdating()) {
      return false
    }

    const now = Date.now()
    const millisecondsSinceLastRefresh = now - this.lastRefreshTime

    if (millisecondsSinceLastRefresh < refreshIntervalMilliseconds) {
      return false
    }

    return true
  }

  refresh() {
    if (this.refreshing) {
      return
    }

    this.refreshAbortController = new AbortController()
    const signal = this.refreshAbortController.signal
    this.refreshing = true
    this.lastRefreshTime = Date.now()

    const refreshPath = this.refreshPathValue
    const headers = {
      Accept: "text/vnd.turbo-stream.html"
    }

    fetch(refreshPath, { headers, signal })
      .then(response => response.text())
      .then(
        html => {
          Turbo.renderStreamMessage(html)
        },
        error => {
          Sentry.captureException(error)
        }
      )
      .finally(() => {
        this.refreshing = false
        this.refreshAbortController = null
      })
  }

  abortRefresh() {
    if (this.refreshAbortController) {
      this.refreshAbortController.abort()
    }
  }

  change(event) {
    const input = event.target

    // Avoid updating disabled inputs, which can happen if a modification is
    // made to an input, and while it is still focused, an update disables that
    // input before it is blurred - Aaron, Wed Aug 09 2023
    if (input.disabled) {
      return
    }

    setInputValueAttributes(input)

    const form = input.form
    this.initiateChange(input.name, form)
  }

  initiateChange(name, form) {
    this.abortRefresh()

    this.morphIgnoreInputs(name, true, form)
    this.changedElementNames.add(name)
    this.debug(`Recorded change newCount=${this.changedElementNames.size} name=${name}`)

    this.requestUpdate(form)
  }

  requestUpdate(form) {
    if (this.isUpdating()) {
      return
    }

    if (this.updateDelay > 0) {
      this.debug(`Delaying update ${this.updateDelay}ms`)
    }

    this.updating = true

    // Ensure update happens on the next tick, rather than the current, so that
    // an event handler may make additional changes to the form before the
    // update is submitted.
    // - Aaron, Shaun, Fri Jan 5 2023
    setTimeout(() => {
      this.update(form)
    }, this.updateDelay)
  }

  morphIgnoreInputs(name, ignore, form) {
    const formElements = form.elements

    for (const elementName in formElements) {
      if (elementName === name) {
        const element = formElements[elementName]

        if (element.forEach) {
          element.forEach(element => {
            Morph.ignore(element, ignore)
          })
        } else {
          Morph.ignore(element, ignore)
        }
      }
    }
  }

  update(form) {
    this.updatingElementNames = this.changedElementNames
    this.changedElementNames = new Set()

    const actions = this.actions
    this.actions = {}

    const updateRequestBody = (event) => {
      for (const entry of [...event.detail.formSubmission.fetchRequest.body.entries()]) {
        const entryName = entry[0]

        if (this.updatingElementNames.has(entryName)) {
          continue
        }

        if (entryName === 'authenticity_token') {
          continue
        }

        if (entryName.startsWith('_')) {
          continue
        }

        event.detail.formSubmission.fetchRequest.body.delete(entryName)
      }

      for (const [name, value] of Object.entries(actions)) {
        event.detail.formSubmission.fetchRequest.body.append(name, value)
      }

      event.detail.formSubmission.fetchRequest.body.append("_method", "patch")
    }

    const completeUpdate = () => {
      // This function is called on turbo:submit-end, but the turbo stream morph
      // that the server responds with will not be applied until later. The only
      // known way to ensure that code can be run immediately before a morph and
      // immediately after is to add an event listener for
      // turbo:before-stream-render and override the event.detail.render method.
      //
      // In the overridden render method:
      //
      // 1. All fields that have been updated have their morph ignore removed
      //
      // 2. The original render is invoked, which applies the morph
      //
      // 3. The update is marked as complete
      //
      // 4. If there is a pending update, requestUpdate is invoked
      //
      // This code was originally added to prevent a race condition wherein a
      // field that was filled in could be subsequently cleared by a previous
      // update's morph being applied.
      // - Aaron, Thu Mar 21 2024
      const beforeStreamRender = (event) => {
        const stream = event.detail.newStream
        const action = stream.getAttribute("action")

        if (action !== "morph") {
          return
        }

        const target = stream.getAttribute("target")

        if (target !== this.element.id) {
          return
        }

        document.removeEventListener("turbo:before-stream-render", beforeStreamRender)

        const render = event.detail.render

        event.detail.render = (self) => {
          // This must be done here to ensure that there is still a pending update
          // when the update (morphdom) happens for this update. If we did it in
          // updateRequestBody, a twice updated field may flash its old value
          // - Aaron, Wed Jul 20 2022
          this.updatingElementNames.forEach(name => {
            // Do not unignore if there is another change enqueued
            if (!this.changedElementNames.has(name)) {
              this.morphIgnoreInputs(name, false, form)
            }
          })
          this.updatingElementNames.clear()

          render(self)

          this.updating = false

          this.debug(`Completed update newCount=${this.changedElementNames.size}`)

          if (this.hasPendingUpdates()) {
            this.requestUpdate(form)
          }
        }
      }

      document.addEventListener("turbo:before-stream-render", beforeStreamRender)
    }

    // turbo:submit-start does not happen synchronously, so we cannot add it
    // and immediately remove it, which is necessary to ensure that we do not
    // leave the event listener around for a different form submission. On the
    // other hand, turbo:before-fetch-request is synchronous, so we can add it
    // and then remove it and use it to install the turbo:submit-start only
    // when we are sure that it is this update that is being submitted.
    // - Aaron, Wed Jul 20 2022
    const addListener = () => {
      form.addEventListener("turbo:submit-start", updateRequestBody, { once: true })
    }
    form.addEventListener("turbo:before-fetch-request", addListener, { once: true })

    const ignoreServerErrors = (event) => {
      // The default behavior of turbo is to render the server error to the
      // user. Since we do not always control this error (our application
      // gateway can serve error pages that it controls), we do not render the
      // error page. - Aaron, Thu Apr 27 2023
      const response = event.detail.fetchResponse

      if (response.serverError) {
        this.debug(`Update failed due to server error`)
        event.preventDefault()

        Sentry.withScope(scope => {
          scope.setTag("statusCode", response.statusCode)
          scope.setExtra("headers", response.response.headers)

          Sentry.captureMessage("Collaborative form change failed", "error")
        })

        this.updatingElementNames.forEach(name => {
          this.changedElementNames.add(name)
        })

        this.updatingElementNames.clear()

        this.updating = false

        form.removeEventListener("turbo:submit-end", completeUpdate)

        this.updateDelay += 250
        this.updateDelay = Math.min(this.updateDelay, 5000)

        this.requestUpdate(form)
      } else {
        this.updateDelay = 0
      }
    }
    form.addEventListener("turbo:before-fetch-response", ignoreServerErrors, { once: true })

    form.addEventListener("turbo:submit-end", completeUpdate, { once: true })

    form.requestSubmit()

    form.removeEventListener("turbo:before-fetch-request", addListener)
  }

  hasPendingActions() {
    return Object.keys(this.actions).length > 0
  }

  hasPendingUpdates() {
    return this.changedElementNames.size > 0 || this.hasPendingActions()
  }

  isUpdating() {
    return this.updating
  }

  debug(message) {
    if (this.debugValue) {
      console.log("Collaborative form - " + message)
    }
  }
}
