import { Controller } from "@hotwired/stimulus";
import "tom-select";
import * as Morph from "web-ui/turbo/stream-actions/morph";
import * as FloatingUI from "@floating-ui/dom";

// Connects to data-controller="async-select"
export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    const input = this.element.querySelector("input[id]")
    const label = input.dataset["selectedLabel"]
    const id = input.value

    document.addEventListener("turbo:before-cache", this.beforeTurboCache)

    const items = []
    const options = []

    if (id) {
      items.push(id)
      options.push({ id, label })
    }

    this.tomSelect = new TomSelect(input, {
      valueField: "id",
      labelField: "label",
      openOnFocus: false,
      searchField: [],
      items: items,
      options: options,
      maxItems: 1,
      sortField: {
        field: "label",
        direction: "asc",
      },
      // A turbo form listens to all change events to any input on the form and collects them to be submitted
      // This input should not trigger updates to the form - Matt, Scott A, Thu Mar 16 2023
      controlInput: '<input onchange="(function(e) { e.stopPropagation() })(event)">',
      render: {
        loading: () => '<div class="loading"></div>',
      },
      load: this.search,
      onFocus: () => Morph.ignore(this.element, true),
      onBlur: () => {
        Morph.ignore(this.element, false)
        this.popStashedItem()
      },
      onItemAdd: (value, item) => {
        const label = item.textContent

        const input = this.element.querySelector("input[id]")
        input.dataset.selectedLabel = label

        this.clearStashedItem()
      },
      onType: (term) => {
        this.stashItem()

        this.tomSelect.clearOptions()

        if (!term) {
          this.tomSelect.close()
        }
      },
      onDropdownOpen: (dropdown) => {
        this.disableAutoUpdate = FloatingUI.autoUpdate(
          this.tomSelect.control_input,
          dropdown,
          this.updatePosition
        )
      },
    })

    Morph.ignore(this.tomSelect.wrapper, true)

    this.observeDataAttributes(input)
  }

  // Store the current selected item and clear item list.
  // Clearing the item list fixes a couple of issues:
  // 1) Selected item is no longer the first item in the search results
  // 2) Selected item was present in the dropdown when loading
  // Stored selected item will be readded when a blur occurs
  // - Matt, Scott A, Thu Mar 16 2023
  stashItem = () => {
    const id = this.tomSelect.items[0]

    if (id) {
      const { label } = this.tomSelect.options[id]
      const item = { id, label }

      this.stashedItem = item
      this.tomSelect.clear(true)
    }
  }

  clearStashedItem = () => {
    this.stashedItem = null
  }

  popStashedItem = () => {
    if (this.stashedItem) {
      this.tomSelect.addOption(this.stashedItem)
      this.tomSelect.addItem(this.stashedItem.id, true)

      this.clearStashedItem()
    }
  }

  updatePosition = () => {
    FloatingUI.computePosition(
      this.tomSelect.control_input,
      this.tomSelect.dropdown,
      {
        placement: "bottom",
        middleware: [FloatingUI.flip({ padding: 16 })],
      }
    ).then(({ placement }) => {
      if (placement === "bottom") {
        this.tomSelect.dropdown.classList.add("bottom")
        this.tomSelect.dropdown.classList.remove("top")
      } else {
        this.tomSelect.dropdown.classList.add("top")
        this.tomSelect.dropdown.classList.remove("bottom")
      }
    })
  }

  beforeTurboCache = () => {
    this.tomSelect.destroy()
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.beforeTurboCache)
    this.observer.disconnect()
    this.tomSelect.destroy()
  }

  // When a form value is persisted, rendering an async-select requires the label and value to be provided
  // to tom-select in order to display the selected item
  // When used within a collaborative form, subsequent changes to the selected item come through as mutations
  // to the attributes of the underlying input
  // This watches the value and data-selected-label attributes, and synchronizes them to tom-select
  // - Matt, Scott A, Thu Mar 16 2023
  observeDataAttributes(element) {
    this.observer = new MutationObserver((mutationRecords) => {
      const option = { id: null, label: null }

      const labelRecord = mutationRecords.find(record => record.attributeName === "data-selected-label")
      const valueRecord = mutationRecords.find(record => record.attributeName === "value")

      // tom-select alters attributes on the input that triggers mutations outside of what we're concerned with
      // We only proceed if the mutations include both value and label - Matt, Scott A, Thu Mar 16 2023
      if (!(labelRecord && valueRecord)) {
        return
      }

      const input = labelRecord.target

      option.id = input.value
      option.label = input.dataset["selectedLabel"]

      const silent = true
      if (option.id && option.label) {
        this.tomSelect.addOption(option)
        this.tomSelect.addItem(option.id, silent)

        return
      }

      this.tomSelect.clear(silent)
    })

    this.observer.observe(element, { attributes: true })
  }

  search = async (term, callback) => {
    if (this.abortController) {
      this.abortController.abort()
    }

    this.abortController = new AbortController()

    const signal = this.abortController.signal

    try {
      const url = `${this.urlValue}?search_term=${encodeURIComponent(term)}`
      const response = await fetch(url, { signal })
      const json = await response.json()

      // ## Is this still needed?
      // ## Needs to be tested with real search data
      // ## - Matt, Scott A, Thu Mar 16 2023
      this.tomSelect.clearOptions()

      // As a user types, multiple searches are requested and aborted
      // This insures only the results that match the most current search term
      // are applied - Matt, Scott A, Thu Mar 16 2023
      if (term === this.tomSelect.lastValue) {
        callback(json)
      } else {
        callback([])
      }
    } catch (error) {
      callback([])

      // error code (20) is equivalent to an AbortError
      // See: https://developer.mozilla.org/en-US/docs/Web/API/DOMException#error_names
      // Abort errors will occur anytime a search is in flight and the user
      // types another character - Matt, Scott A, Mon Mar 6 2023
      if (error.code && error.code === 20) {
        return
      }

      Sentry.captureException(error)
    } finally {
      this.abortController = null
    }
  }
}
